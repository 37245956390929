<template>
  <v-autocomplete
    v-model="$store.state.schedule.regionsSelected"
    :items="$store.state.schedule.regions"
    dense
    chips
    small-chips
    label="Regiões"
    multiple
    outlined
    :rules="[v=>(v||'').length > 0 || 'É preciso ao menos uma gegião' ]"
  ></v-autocomplete>
</template>

<script>
import { getTotens } from '../../services/totens';
import _ from 'lodash'
export default {
  data: () => ({
    items: [],
    values: [],
  }),
  mounted() {
    this.$store.state.loading = true;
    getTotens()
      .then((data) => {
        this.$store.state.schedule.totens = data
        this.$store.state.schedule.regions = Object.keys(_.groupBy(data, 'region'));
        this.$store.state.loading = false;
      })
      .catch((err) => {
        this.$store.commit("failed", "ERRO: " + err);
        this.$store.state.loading = false;
      });
  }
};
</script>

<style lang="scss" scoped>
</style>