import axios from 'axios'
import baseUrl from './baseUrl'

const errors = err => {
  console.error(err.response ? err.response.data.stack : err.stack)
  return Promise.reject(err.response ? err.response.data.message:'Houve uma falha na comunição, verifique sua internet e tente novamente')
}

const headers = {token: localStorage.getItem('token')}
const schedules = axios.create({ baseURL: baseUrl.tnAdmin, headers })

export const createSchedule = (schedule) => schedules.post('/schedules', schedule).then(({data}) => data).catch(errors)

export const deleteSchedule = (id) => schedules.delete('/schedules/'+id).then(({data}) => data).catch(errors)

export const updateSchedule = (schedule) => schedules.put('/schedules/'+schedule._id, schedule).then(({data}) => data).catch(errors)

export const getSchedules = (month) => schedules.get('/schedules/'+month).then(({data}) => data).catch(errors)

export const getRegions = () => schedules.get('/schedules/regions').then(({data}) => data).catch(errors)