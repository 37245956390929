<template>
  <div class="text-center">
    <v-dialog v-model="$store.state.schedule.showDialog" width="600">
      <Windows />
    </v-dialog>
  </div>
</template>

<script>
import Windows from './Windows'
export default {
  components: { Windows},
};
</script>