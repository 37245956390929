<template>
<div>
    <v-time-picker
      v-model="$store.state.schedule.selectedHours"
      scrollable
      format="24hr"
    ></v-time-picker>
    
</div>
</template>

<script>
  export default {
    data () {
      return {
        picker: null,
      }
    },
  }
</script>