<template>
  <v-card class="mx-auto">
      <v-form ref="form">
    <v-card-title class="title font-weight-regular justify-space-between">
      <span>{{ currentTitle }}</span>
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="24"
        v-text="$store.state.schedule.step"
      ></v-avatar>
    </v-card-title>

    <v-window v-model="$store.state.schedule.step">
      <v-window-item :value="1">
        <v-row>
          <v-col></v-col>
          <v-col><Clock /></v-col>
          <v-col></v-col>
        </v-row>
      </v-window-item>

      <v-window-item :value="2">
        <Playlists />
      </v-window-item>

      <v-window-item :value="3">
        <v-card-text>
          <div class="pa-4 text-center">
            <v-col>
              {{ $store.state.schedule.playlistSelected.name }}
            </v-col>
          </div>
          <div class="pa-4 text-center">
            <totens
              v-if="$store.state.schedule.playlistSelected.type == 'Totens'"
            ></totens>
            <regions
              v-if="$store.state.schedule.playlistSelected.type == 'Regiões'"
            ></regions>
          </div>

        </v-card-text>
      </v-window-item>
    </v-window>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="$store.state.schedule.step === 1"
        text
        @click="$store.state.schedule.step--"
      >
        Voltar
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :disabled="$store.state.schedule.step === 2"
        depressed
        @click="$store.state.schedule.step++"
        v-if="$store.state.schedule.step != 3"
      >
        Próximo
      </v-btn>

      <v-btn v-else color="primary" depressed @click="create"> Criar </v-btn>
    </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import Playlists from "./Playlists";
import Clock from "./Clock";
import Totens from "./Totens";
import Regions from "./Regions";
import {createSchedule} from '../../services/schedules'
export default {
  components: { Playlists, Clock, Totens, Regions },
  data: () => ({
    step: 1,
    colors: {
      Regiões: "green",
      Totens: "orange",
      Padrão: "grey darken-1",
    },
  }),

  computed: {
    currentTitle() {
      switch (this.$store.state.schedule.step) {
        case 1:
          return "Escolha um horário para iniciar a playlist";
        case 2:
          return "Selecione uma playlist abaixo";
        default:
          return "Selecione a região/totem para exibição";
      }
    },
  },
  methods: {
    create() {
        const isValid = this.$refs.form.validate()
        if(!isValid)return this.$store.commit('failed', "Preencha os campos obrigatórios")
        let totens = this.$store.state.schedule.totens.filter(toten => this.$store.state.schedule.regionsSelected.includes(toten.region))
        totens = totens.length ? totens : this.$store.state.schedule.totens.filter(toten => this.$store.state.schedule.totensSelected.includes(toten._id))
        const start = new Date(this.$store.state.schedule.startDate)
        const end = new Date(this.$store.state.schedule.endDate)
        end.setHours(23)
        end.setMinutes(59)
        const hours = this.$store.state.schedule.selectedHours.split(':')
        start.setHours(hours[0])
        start.setMinutes(hours[1])
        const event = {
        name:
          this.$store.state.schedule.playlistSelected.name +
          " - " +
          this.$store.state.schedule.playlistSelected.type,
        start: start,
        end,
        color: this.colors[this.$store.state.schedule.playlistSelected.type],
        totens: totens.length ? totens : this.$store.state.schedule.totensSelected,
        playlist: this.$store.state.schedule.playlistSelected,
        repeat: this.$store.state.schedule.repeat,
        timed: false,
        user: this.$store.state.user
      }
      
      this.$store.state.schedule.newSchedule = event
      this.$store.state.schedule.showDialog = false;
      this.$store.commit('schedule/reset')
      this.$store.state.loading=true
      createSchedule(event).then(data=>{
          data = {...data, start: new Date(data.start), end: new Date(data.end)}
          this.$store.state.schedule.events.push(data);
          this.$store.state.loading=false
          this.$store.commit('success', 'Agenda salva com suceso')
      })
      .catch(err => {
          this.$store.commit('failed', 'ERRO '+err)
          this.$store.state.loading=false
        })
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>