<template>
  <v-autocomplete
    v-model="$store.state.schedule.totensSelected"
    :items="$store.state.schedule.totens"
    item-value="_id"
    item-text="name"
    dense
    chips
    small-chips
    label="Totens"
    multiple
    outlined
    :rules="[v=>(v||'').length > 0 || 'É preciso ao menos um totem para exibição' ]"
  ></v-autocomplete>
</template>

<script>
import { getTotens } from '../../services/totens';
export default {

  mounted() {
    this.$store.state.loading = true;
    getTotens()
      .then((data) => {
        this.$store.state.schedule.totens = data;
        this.$store.state.loading = false;
      })
      .catch((err) => {
        this.$store.commit("failed", "ERRO: " + err);
        this.$store.state.loading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
</style>