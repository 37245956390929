<template>
  <div>
    <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="300px"
      min-width="300px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          readonly
          :value="dates"
          label="Período de Exibição"
          persistent-hint
          append-icon="mdi-calendar"
          v-bind="attrs"
          v-on="on"
          id="validate"
          hide-details
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        range
        :weekday-format="getWeek"
        :month-format="getMonth"
        :header-date-format="getMonthComplet"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  data: () => ({
    dates: "",
    menu1: false,
    date: [],
    months: [
      "jan",
      "fev",
      "mar",
      "abr",
      "mai",
      "jun",
      "jul",
      "ago",
      "set",
      "out",
      "nov",
      "dez",
    ],
    weeks: ["S", "T", "Q", "Q", "S", "S", "D"],
    completeMonths: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
  }),
  methods: {
    getWeek(data) {
      return this.weeks[new Date(data).getDay()];
    },
    getMonth(data) {
      return this.months[data.split("-")[1] - 1];
    },
    getMonthComplet(data) {
      const date = data.split("-");
      return this.completeMonths[date[1] - 1] + " - " + date[0];
    },
  },

  watch: {
    date(value) {
      if (value.length == 2) {
        const start = new Date(value[0]+'T03:00:00.000Z');
        const end = new Date(value[1]+'T03:00:00.000Z');
        const dates = [start, end]
          .sort((a, b) => a - b)
          .map((date) => date.toJSON());
        this.$store.state.schedule.startDate = dates[0];
        this.$store.state.schedule.endDate = dates[1];
        
        this.dates = dates
          .map((date) => new Date(date).toLocaleDateString())
          .join(" ~ ");
        this.$store.state.schedule.step = 1;
        this.$store.state.schedule.showDialog = true;
      }
    },
  },

  mounted() {
    //this.date = [new Date().toLocaleDateString().split('/').reverse().join('-'), new Date().toLocaleDateString().split('/').reverse().join('-')]
  },
};
</script>