<template>
  <div>
    <Dialog />
    <v-card>
      <v-card-text>
        <v-row class="fill-height">
          <v-col>
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Hoje
                </v-btn>

                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <div v-if="$refs.calendar" >
                  {{ $refs.calendar.title }}
                </div>

                <v-spacer></v-spacer>

                <v-col><DatePicker /></v-col>

                <v-spacer></v-spacer>
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Dia</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Semana</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Mês</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                      <v-list-item-title>4 Dias</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="$store.state.schedule.events"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @click:time="viewTime"
                
                :month-format="getMonth"
                :weekday-format="getWeek"
              ></v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card color="grey lighten-4" min-width="350px" flat>
                  <v-toolbar :color="selectedEvent.color" dark>
                    <v-btn icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                   
                    <div class="title" v-if="selectedEvent.playlist" @click="toPlaylist" >
                      {{selectedEvent.name}}asdfasfd
                    </div>
                    
                  </v-toolbar>

                  <v-card-text style="font-size: 20px">
                    <h3>Período de exibição</h3><br>
                    De: <input type="text" v-mask="'##/##/#### ##:##:##'" v-model="start"> <br><br>
                    até: <input type="text" v-mask="'##/##/#### ##:##:##'" v-model="end">
                    </v-card-text>
                  
                  <v-card-actions>
                    <v-btn text color="secondary" @click="selectedOpen = false">
                      Cancelar
                    </v-btn>
                   
                    <v-btn text color="error" @click="deleteEvent">
                      Deletar
                    </v-btn>
                   
                    <v-btn text color="blue" @click="save">
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Dialog from "./Dialog";
import DatePicker from "./datePicker";
import { deleteSchedule, getSchedules, updateSchedule } from '../../services/schedules';

import _ from 'lodash'
export default {
  components: { Dialog, DatePicker },
  // computed: {
  //   start: {
  //     get: function(){
  //       return new Date(this.selectedEvent.start).toLocaleString()
  //     },
  //     set: function(data){
        
  //       const splited = data.split(' ')
  //       const date = splited[0].split('/').reverse().join('-')
  //       const hours = splited[1]
  //       this.selectedStart = new Date(date + ' ' +hours)+'111'
  //     }
  //   }
  // },
  watch: {
    selectedOpen(value){
      if(value){
        this.start = this.selectedEvent.start.toLocaleString()
        this.end = this.selectedEvent.end.toLocaleString()
      }
    }
  },
  data: () => ({
    start: '',
    end:'',
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Mês",
      week: "Semana",
      day: "Dia",
      "4day": "4 Dias",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    weeks: [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
    ],
  }),
  methods: {
    async save(){
      const start = new Date(this.start.split(' ')[0].split('/').reverse().join('-') +' '+ this.start.split(' ')[1])
      const end = new Date(this.end.split(' ')[0].split('/').reverse().join('-') + ' ' + this.end.split(' ')[1])
      if(start.toLocaleString() != this.selectedEvent.start.toLocaleString() || end.toLocaleString() != this.selectedEvent.end.toLocaleString()){
      
      this.start = start.toLocaleString()
      this.end = end.toLocaleString()
      this.$store.commit('setLoading', true)
      await updateSchedule(this.selectedEvent)
        .then(()=>{
          this.selectedEvent.start = start
          this.selectedEvent.end = end
          this.$store.commit('success', 'Sucesso')
          }).catch(err => this.$store.commit('failed', 'Nada foi modificado', err))
      this.$store.commit('setLoading', false)
      }
    },
    toPlaylist(){
      this.$store.state.playlist.editedPlaylist = this.selectedEvent.playlist
      this.$store.state.playlist.itemsSelected = this.selectedEvent.playlist.items
      this.$router.push('/playlists/edit/'+this.selectedEvent.playlist._id) 
    },
    deleteEvent(){
      const index = _.findIndex(this.$store.state.schedule.events, {_id: this.selectedEvent._id})
      this.$store.state.loading = true
      deleteSchedule(this.selectedEvent._id)
      .then(() => {
        this.$store.state.loading = false
        this.$store.commit('success', 'Agenda deletada com sucesso')
        this.$store.state.schedule.events.splice(index,1)
        this.selectedOpen = false
      })
      .catch(err => {
        this.$store.commit('failed', 'Falha ao tentar remover agenda '+err)
        this.$store.state.loading = false

      })
      
    },
    createEvent() {
      
      // this.events.push({
      //     name: String(Date.now()),
      //     start: new Date(this.$store.state.schedule.startDate),
      //     end: new Date(this.$store.state.schedule.endDate),
      //     color: this.colors[this.rnd(0, this.colors.length - 1)],
      //     timed: false,
      //   })
    },
    getWeek(data) {
      return this.weeks[data.weekday];
    },
    getMonth(data) {
      return this.months[data.month-1];
    },
    viewTime({ time }) {
      //this.$store.state.schedule.showDialog = true;
      this.$store.state.schedule.selectedHours = time;
    },
    viewDay({ date }) {
      //this.$store.state.schedule.showDialog = true;
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    //updateRange({ start, end }) {
      //const events = [];
      // const min = new Date(`${start.date}T00:00:00`);
      // const max = new Date(`${end.date}T23:59:59`);
      // const days = (max.getTime() - min.getTime()) / 86400000;
      // const eventCount = this.rnd(days, days + 20);

      // for (let i = 0; i < eventCount; i++) {
      //   const allDay = this.rnd(0, 3) === 0;
      //   const firstTimestamp = this.rnd(min.getTime(), max.getTime());
      //   const first = new Date(firstTimestamp - (firstTimestamp % 900000));
      //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
      //   const second = new Date(first.getTime() + secondTimestamp);

      //   events.push({
      //     name: this.names[this.rnd(0, this.names.length - 1)],
      //     start: first,
      //     end: second,
      //     color: this.colors[this.rnd(0, this.colors.length - 1)],
      //     timed: !allDay,
      //   });
      // }
      // events.push(
      //   {
      //     name: "Birthday",
      //     start: new Date('2021-04-12T04:30:00.000Z'),
      //     end: new Date("2021-04-13T09:15:00.000Z"),
      //     color: "blue",
      //     timed: false,
      //   },
      // );
      //this.events = events;
    //},
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
  mounted(){
    this.$store.state.loading = true
    getSchedules(new Date().getMonth())
    .then(data => {
      data.forEach(item =>{
        item.start = new Date(item.start)
        item.end = new Date(item.end)
      })
      this.$store.state.loading = false
      this.$store.state.schedule.events = data
    })
    .catch(err => {
      this.$store.commit('failed', 'Falha ao buscar Agendas '+err)
      this.$store.state.loading = false
    })
  }
};
</script>

<style scoped>
.title{
  color: white!important;
  cursor:pointer;
}
</style>