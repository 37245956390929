<template>
  <v-data-table
    :headers="headers"
    :items="$store.state.playlist.data"
    sort-by="createdAt"
    class="elevation-1"
    :loading="loading"
    loading-text="Carregando..."
    :search="search"
    single-select
    :items-per-page="5"
    :options.sync="options"
    :server-items-length="$store.state.playlist.totalItems"
  >

  <template v-slot:top>
      <v-toolbar flat>
        
        <v-text-field
          v-model="search"
          label="Procurar playlist"
          class="mx-4"
          hide-details
        ></v-text-field>

      </v-toolbar>
    </template>

    <template v-slot:item="{item}">
      <tr @click="teste(item)" class="tr" style="cursor:pointer">
        <td>{{item.name}}</td>
        <td>{{item.type}}</td>
        <td>{{item.user.name}}</td>
        <td>{{item.items.filter(data => data.itemType=="Notícia").length}}</td>
        <td>{{item.items.filter(data => data.itemType=="Anúncio").length}}</td>
      </tr>
    </template>
    
    <template v-slot:no-data=""> Nenhum totem cadastrado </template>

  </v-data-table>
</template>

<script>
import {gePlaylists} from '../../services/playlists'
export default {
  data: () => ({
    options: {sortBy: ['createdAt'], sortDesc: [true], filter: {}},
    search: '',
    loading: false,
    loadingDelete:false,
    dialogDelete: false,
    headers: [
      { text: "Nome", value: "name" },
      { text: "Tipo", value: "type" },
      { text: "Usuário", value: "user.name" },
      { text: "Notícias", value: "news" },
      { text: "Anúncios", value: "ads" },
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    itemToRemove: {}
  }),
  methods:{
    teste(item){
      this.$store.state.schedule.playlistSelected = Object.assign({},item)
      this.$store.state.schedule.step ++
    }
  },

  watch:{
    options: {
        handler () {
          this.loading = true;
          clearTimeout(this.timeoutToSearch)
          this.timeoutToSearch = setTimeout(()=>{
            gePlaylists(this.options).catch((err) => this.$state.commit("failed", "ERRO: " + err))
            .finally(()=>this.loading = false);
          }, 300)
        },
        deep: true,
      },
      search(v){
        this.options.filter = {$or: [{name: { $regex: v, $options: 'i' }}, {"user.name": {$regex: v, $options: 'i'}}]}        
      }
  }
};
</script>